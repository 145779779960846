<template>
  <!-- 页面name：运营任务 -->
  <div class="dict">
    <!--  v-if="isAdd" -->
    <!-- 表单 -->
    <div class="form-header-btn btn1">
      <div class="title">运营任务发布</div>
      <div>
        <el-button type="primary" v-if="isAdd" @click="handleAdd"
          >新增</el-button
        >
      </div>
    </div>
    <div class="form-header-btn btn2">
      <div>
        <el-form
          :model="form"
          onsubmit="return false"
          :inline="true"
          class="search-form"
        >
          <el-form-item>
            <el-cascader
              :options="orgList"
              v-model="form.orgList"
              :props="props"
              @change="changeOrg"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.theme" placeholder="运营主题" />
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.templateName" placeholder="运营模板" />
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.isPublish" placeholder="请选择状态">
              <el-option label="待发布" value="0"> </el-option>
              <el-option label="已发布" value="1"> </el-option>
              <el-option label="已终止" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.publishName" placeholder="发布人" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button
              @click="resetFun"
              icon="el-icon-refresh-right"
              class="reset"
              >重置</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-checkbox @change="changeSearch" v-model="form.publishUser"
              >自己发布</el-checkbox
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 0.52083vw)'"
      :autoHeight="false"
    >
      <el-table-column
        prop="theme"
        label="运营主题"
        align="center"
        min-width="200"
        slot="theme"
      ></el-table-column>
      <el-table-column
        prop="templateName"
        min-width="160"
        label="运营模板"
        align="center"
        slot="templateName"
      ></el-table-column>
      <el-table-column
        prop="totalPredictDay"
        label="总计时长"
        align="center"
        min-width="80"
        slot="totalPredictDay"
      ></el-table-column>
      <el-table-column
        prop="receivingRangeStr"
        min-width="200"
        label="接收范围"
        align="center"
        slot="receivingRangeStr"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.receivingRangeStr"
            placement="top"
          >
            <div class="moreInfo">{{ scope.row.receivingRangeStr }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="publishName"
        label="发布人"
        min-width="100"
        align="center"
        slot="publishName"
      ></el-table-column>
      <el-table-column
        min-width="150"
        prop="scheduleTime"
        slot="scheduleTime"
        label="定时发布时间"
        align="center"
      ></el-table-column>
      <el-table-column
        min-width="150"
        prop="publishTime"
        slot="publishTime"
        label="实际发布时间"
        align="center"
      ></el-table-column>
      <el-table-column
        min-width="100"
        prop="isPublish"
        slot="isPublish"
        label="状态"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isPublish">{{
            scope.row.isPublish.label
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        slot="handle"
        align="center"
        fixed="right"
        min-width="240"
      >
        <template slot-scope="scope">
          <!-- v-if="isUpdate" -->
          <el-button
            type="text"
            v-if="
              scope.row.isPublish.value == 0 &&
              isTqfb &&
              userInfo.userId == scope.row.publishUser
            "
            @click="handlePublish(scope.row)"
            >提前发布</el-button
          >
          <el-button type="text" v-if="isView" @click="handleEdit(scope.row, 2)"
            >查看</el-button
          >
          <el-button type="text" @click="handleEdit(scope.row, 3)" v-if="isCopy"
            >复制</el-button
          >
          <!--  scope.row.isPublish.value == 0 &&-->
          <el-button
            type="text"
            @click="handleEdit(scope.row, 1)"
            v-if="
              isUpdate &&
              userInfo.userId == scope.row.publishUser
            "
            >编辑</el-button
          >
          <el-button
            type="text"
            @click="handleTerminate(scope.row)"
            v-if="
              scope.row.isPublish.value == 1 &&
              isTermination &&
              userInfo.userId == scope.row.publishUser
            "
            ><span class="activeColor">终止</span></el-button
          >
          <!-- <el-popconfirm
            :icon="'el-icon-error'"
            :icon-color="'#C6000B'" 
            title="你确定要删除此项目吗？" 
          >
            <el-button slot="reference"  type="text" class="delBtn" >删除</el-button>
          </el-popconfirm> -->
          <el-popconfirm
            :icon="'el-icon-error'"
            :icon-color="'#C6000B'"
            title="你确定要删除此项目吗？"
            @confirm="handleDel(scope.row)"
            v-if="
              scope.row.isPublish.value == 0 &&
              isDelete &&
              userInfo.userId == scope.row.publishUser
            "
          >
            <el-button
              slot="reference"
              v-if="
                scope.row.isPublish.value == 0 &&
                isDelete &&
                userInfo.userId == scope.row.publishUser
              "
              class="delBtn"
              type="text"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </l-table>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    return {
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "运营主题",
          slotName: "theme",
        },
        {
          label: "运营模板",
          slotName: "templateName",
        },
        {
          label: "总计时长",
          slotName: "totalPredictDay",
        },
        {
          label: "接收范围",
          slotName: "receivingRangeStr",
        },
        {
          label: "完成情况",
          slotName: "userName",
        },
        {
          label: "发布人",
          slotName: "publishName",
        },
        {
          label: "定时发布时间",
          slotName: "scheduleTime",
        },
        {
          label: "实际发布时间",
          slotName: "publishTime",
        },
        {
          label: "状态",
          slotName: "isPublish",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      props: {
        multiple: true,
        value: "label",
        label: "name",
        children: "child",
      },
      form: {
        publishName: null,
        publishUser: null,
        isPublish: null,
        theme: null,
        templateName: null,
        orgList: [],
      },
      tableData: [],
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      isTqfb: false,
      isView: false,
      isCopy: false,
      isTermination: false,
      openLevel: 3,
      orgList: [],

      userInfo: {}, //登录人信息
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          console.log(val);
          this.isUpdate = val.indexOf("operatingTask:edit") != -1; //编辑
          this.isAdd = val.indexOf("operatingTask:add") != -1; //添加
          this.isDelete = val.indexOf("operatingTask:del") != -1; //删除
          this.isTqfb = val.indexOf("operatingTask:tqfb") != -1; //提前发布
          this.isView = val.indexOf("operatingTask:view") != -1; //查看
          this.isCopy = val.indexOf("operatingTask:copy") != -1; //复制
          this.isTermination = val.indexOf("operatingTask:termination") != -1; //终止
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    let userInfoData = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfoData;
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.initOrg();
    this.getPermissionButton(this.$route.path, (data) => {
      console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.init();
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      if (this.form.keyword == "") {
        this.form.keyword = null;
      }
      // this.page.num = 1;
      this.tableLoading = true;
      let publishUser = "";
      if (this.form.publishUser) {
        let userInfoData = JSON.parse(sessionStorage.getItem("userInfo"));
        publishUser = userInfoData.userId;
      }
      // console.log(this.form.orgList)
      let orgList = "";
      if (this.form.orgList) {
        orgList = this.initChooseOrg(this.form.orgList);
      }
      let flag = null;
      let companyLabel = null;
      console.log(this.$store.state.userInfo.authList, "ooooo");
      if (this.$store.state.userInfo.authList) {
        if (this.$store.state.userInfo.authList[0] == "org:z") {
          flag = "headoffice";
        } else if (this.$store.state.userInfo.authList[0] == "org:f") {
          flag = "company";
          companyLabel = this.$store.state.userInfo.schoolList[0].companyLabel;
        }
      }
      console.log(this.$store.state.userInfo.authList[0] == "org:f", "ppp");
      this.$api
        .taskList({
          current: this.page.num,
          size: this.page.size,
          publishName: this.form.publishName,
          publishUser: publishUser,
          isPublish: this.form.isPublish,
          theme: this.form.theme,
          templateName: this.form.templateName,
          receivingRangeIdList: orgList,
          flag: flag,
          companyLabel: companyLabel,
        })
        .then((res) => {
          this.tableLoading = false;
          if (res.data.code == "0") {
            this.tableData = res.data.data.records;
            if (this.tableData) {
              this.tableData.forEach((e) => {});
            }
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableData = [];
            this.page.total = 0;
            this.pageFlag = false;
            this.$message.error(res.data.msg);
          }
        });
    },
    initChooseOrg(val) {
      let chooseArr = val;
      let arr = [];
      for (let index = 0; index < chooseArr.length; index++) {
        const e = chooseArr[index];
        if (e.length > 1) {
          arr.push(e[1]);
        }
      }
      return arr;
    },
    initOrg() {
      this.$api.getSchoolForTree().then((res) => {
        if (res.data.code == "0") {
          this.orgList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    changeSearch() {
      this.searchFun();
    },
    changeOrg(val) {},
    // 查询
    searchFun() {
      this.page.num = 1;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form = {
        publishName: null,
        publishUser: null,
        isPublish: null,
        theme: null,
        templateName: null,
        orgList: [],
      };
      // this.form.keyword = "";
      this.page.num = 1;
      this.init();
    },
    /** 表单-新增 */
    handleAdd() {
      this.$router.push({
        path: "/editOperatingTask",
        query: {
          isEdit: 0,
        },
      });
    },
    /** 行内-编辑 */
    handleEdit(row, type) {
      if (type == 1) {
        // 编辑
        this.$router.push({
          path: "/editOperatingTask",
          query: {
            isEdit: 1,
            id: row.id,
          },
        });
      } else if (type == 2) {
        // 查看
        this.$router.push({
          path: "/editOperatingTask",
          query: {
            isEdit: 2,
            id: row.id,
          },
        });
      } else if (type == 3) {
        // 复制
        this.$router.push({
          path: "/editOperatingTask",
          query: {
            isEdit: 3,
            id: row.id,
          },
        });
      }
    },
    // 提前发布
    handlePublish(row) {
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            }
            // "确定要删除该条数据？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定提前发布这条数据？"
          ),
        ]),
        "操作确认",
        {
          type: "warning",
          iconClass: "el-icon-question",
          closeOnClickModal: false,
          customClass: "warningMessage",
        }
      ).then(() => {
        this.$api.taskListPublish(row.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("发布成功");
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    // 终止任务
    handleTerminate(row) {
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            }
            // "确定要删除该条数据？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定终止该条数据？"
          ),
        ]),
        "操作确认",
        {
          type: "warning",
          iconClass: "el-icon-question",
          closeOnClickModal: false,
          customClass: "warningMessage",
        }
      ).then(() => {
        this.$api.taskListTerminate(row.id).then((res) => {
          if (res.data.code == "0") {
            this.$message.success("终止成功");
            this.init();
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    /** 行内-删除 */
    handleDel(row) {
      // `确定要删除该条数据吗?`
      // const h = this.$createElement;
      // this.$confirm(
      //   h("p", null, [
      //     h(
      //       "span",
      //       {
      //         style:
      //           "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
      //       },
      //       "确定要删除该条数据？"
      //     ),
      //     h(
      //       "div",
      //       {
      //         style:
      //           "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
      //       },
      //       "是否确定删除该条数据，删除后，将无法恢复。"
      //     ),
      //   ]),
      //   "操作确认",
      //   {
      //     type: "warning",
      //     iconClass: "el-icon-question",
      //     closeOnClickModal: false,
      //     customClass: "warningMessage",
      //   }
      // ).then(() => {
      this.$api.taskListDel(row.id).then((res) => {
        if (res.data.code == "0") {
          this.$message.success("删除成功");
          // this.page.num = 1;
          this.init();
        } else {
          this.$message.error(res.data.msg);
        }
      });
      // });
    },
    /** 分页 */
    handleCurrentChange(val) {
      this.page.num = val;
      this.init();
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.page.num = 1;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .tableList {
    height: calc(100% - 116px);
  }
}
.form-header-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sheet-btn {
    background: #2a00b2;
    box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
  }

  /deep/.el-button + .el-button {
    margin-left: 20px;
  }
  &.btn2 {
    margin-bottom: 0px;
  }
  &.btn1 {
    margin-bottom: 16px;
  }
}
.title {
  font-weight: bold;
  font-size: 22px;
}
.moreInfo {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.delBtn {
  margin-left: 10px;
}
</style>
<style>
.warningMessage .el-button {
  width: 98px;
  /* height: 38px; */
}
</style>